import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from
  '@angular/material/dialog';
@Component({
  selector: 'app-presupuesto',
  templateUrl: './presupuesto.component.html',
  styleUrls: ['./presupuesto.component.css']
})
export class PresupuestoComponent implements OnInit {
  public emailstring: string;
  public comentario: string = '';
  public nombre: string = '';
  public telefono: number;
  public comentarioCompleto: string;

  constructor(public dialogRef: MatDialogRef<PresupuestoComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any
  ) { }

  ngOnInit() {
    this.emailstring = "mailto:reformasbale@gmail.com?subject=Solicitud%20Presupuesto&body=" + this.comentario; // add the links to body
  }
  closeModal() {
    this.dialogRef.close();
  }
  cambiaNombre(value){
    this.nombre= value;
  }
  cambiaTelefono(value){
    this.telefono= value;
  }
  cambiaComentario(value) {
    this.comentarioCompleto= value;
    if(value==="" || value===undefined) {    this.comentarioCompleto = " Estoy interesado en conocer un presupuesto" };

    // this.comentarioCompleto = valor + this.nombre;
    if(this.nombre!=="" && this.nombre!==undefined) { this.comentarioCompleto = "Mi nombre es " + this.nombre + ". " + this.comentarioCompleto }
    if(this.telefono!==undefined) { this.comentarioCompleto = this.comentarioCompleto + ",mi teléfono de contacto es " +  this.telefono + "." }
 
    this.emailstring = "mailto:reformasbale@gmail.com?subject=Solicitud%20Presupuesto&body=" + this.comentarioCompleto; // add the links to body
  }
}
