import { Component, OnInit } from '@angular/core';
import { fadeInAnimation } from '../_animations/index';
import { trigger, style, animate, transition } from "@angular/animations";
// import 'aos/dist/aos.css';
// declare let AOS: any;
export const fadeInOut = (name = "fadeInOut", duration = 0.1) =>
  trigger(name, [
    transition(":enter", [
      style({ opacity: 0 }),
      animate(`${duration}s ease-in-out`)
    ]),
    transition(":leave", [
      animate(`${duration}s ease-in-out`, style({ opacity: 0 }))
    ])
  ]);
@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
  // animations: [fadeInAnimation],
  //  animations: [fadeInOut("fadeInOut-1", 0.3)]


})
export class ServicesComponent implements OnInit {
  show = true;
  constructor() { }
  members: {title: string, subtitle: string, content: string, url: string}[] = [
    {title: 'Title', subtitle: 'Subtitle', content: 'Content here', url: 'https://material.angular.io/assets/img/examples/shiba2.jpg'},
      ];

  ngOnInit() {
      // AOS.init();
    
  }

}
