import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import { TitleWebComponent } from './title-web/title-web.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatSliderModule} from '@angular/material/slider';
import { HeaderComponent } from './header/header.component';
import {MatMenuModule} from '@angular/material/menu';
import { ServicesComponent } from './services/services.component';
import { ProyectsComponent } from './proyects/proyects.component';
import { OpinionesComponent } from './opiniones/opiniones.component';
import { MatDialogModule, MatDialogRef, MatInputModule, MatListModule, MatSidenavModule, MatToolbarModule, MAT_DIALOG_DATA } from '@angular/material';
import { PresupuestoComponent } from './presupuesto/presupuesto.component';
import { FormsModule } from '@angular/forms';
import { PiepaginaComponent } from './piepagina/piepagina.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { OwlModule } from 'ngx-owl-carousel';
import { TrabajoComponent } from './trabajo/trabajo.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { ElegirnosComponent } from './elegirnos/elegirnos.component';
import { InstalacionesComponent } from './instalaciones/instalaciones.component';
import { LightboxModule } from 'ngx-lightbox';
import { PoliticaComponent } from './politica/politica.component';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { TranslateModule } from '@ngx-translate/core';
import { VideoComponent } from './video/video.component';
import { SafePipeModule } from 'safe-pipe';

// import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';
const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: 'localhost'// it is recommended to set your domain, for cookies to work properly
  },
  palette: {
    popup: {
      background: '#1D1D1B'
    },
    button: {
      background: '#F29200'
    }
  },
  position: "bottom-right",
  theme: 'edgeless',
  type: 'opt-in',
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": '{{messagelink}}{{compliance}}'
  },
  elements:{
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}} 
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link" (click)="openPoliticaPrivacidad() target="_blank">{{cookiePolicyLink}}</a>
      <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank">{{privacyPolicyLink}}</a> 
         </span>
    `,
  },
  revokable: true,
  // elements:{
  //   messagelink: `
  //   <span id="cookieconsent:desc" class="cc-message">{{message}} 
  //     <a aria-label="learn more about cookies" tabindex="0" class="cc-link" (click)="openPoliticaPrivacidad() target="_blank">{{cookiePolicyLink}}</a>, 
  //     <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank">{{privacyPolicyLink}}</a> and our 
  //     <a aria-label="learn more about our terms of service" tabindex="2" class="cc-link" href="{{tosHref}}" target="_blank">{{tosLink}}</a>
  //   </span>
  //   `,
  // },
  content:{
    message: 'Este sitio web utiliza cookies para garantizar que obtenga la mejor experiencia en nuestro sitio web.',
    
    // cookiePolicyLink: 'Cookie Policy',
    // cookiePolicyHref: 'https://cookie.com',

    // privacyPolicyLink: 'Privacy Policy',
    // privacyPolicyHref: 'https://privacy.com',

    tosLink: 'Terms of Service',
    tosHref: 'https://tos.com',
    dismiss: "Got it!",
        allow: "Permitir",
        deny: "Cancelar",
        link: "Learn more",
        policy: "Política de privacidad"
  }
};


@NgModule({
  declarations: [
    AppComponent,
    TitleWebComponent,
    HeaderComponent,
    ServicesComponent,
    ProyectsComponent,
    OpinionesComponent,
    PresupuestoComponent,
    PiepaginaComponent,
    TrabajoComponent,
    ElegirnosComponent,
    InstalacionesComponent,
    PoliticaComponent,
    VideoComponent
   
  ],
  entryComponents:[PresupuestoComponent,PoliticaComponent],
  imports: [
    NgcCookieConsentModule.forRoot(cookieConfig),
    TranslateModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatTabsModule,
    MatGridListModule,
    MatCardModule,
    FlexLayoutModule,
    MatButtonModule,
    MatSliderModule,
    MatMenuModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    MatDialogModule,
    BrowserModule ,
    MatIconModule ,
    MatListModule ,
    MatToolbarModule ,
    MatSidenavModule ,
    MatButtonModule ,
    FlexLayoutModule,
    ScrollingModule,
    MatDialogModule,
    BrowserAnimationsModule,
    OwlModule,
    GoogleMapsModule,
    LightboxModule,
    BrowserModule,
    SafePipeModule
    // CarouselModule 
    // AnimateOnScrollModule.forRoot() 

  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    },
    { 
      provide: MAT_DIALOG_DATA, 
      useValue: {} 
    },
 ],
  bootstrap: [AppComponent]
})

export class AppModule {

 
 }
