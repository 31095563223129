import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-politica',
  templateUrl: './politica.component.html',
  styleUrls: ['./politica.component.css']
})
export class PoliticaComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<PoliticaComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any
  ) { }
 
  ngOnInit() {
  }
  closeModal(){
    this.dialogRef.close();
  }
}
