import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgcCookieConsentService, NgcInitializeEvent, NgcStatusChangeEvent, NgcNoCookieLawEvent } from 'ngx-cookieconsent';
import { TranslateService } from '@ngx-translate/core';
// import AOS from 'aos';
import 'aos/dist/aos.css';
import { Subscription } from 'rxjs';
import { HeaderComponent } from './header/header.component';
declare let AOS: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit, OnDestroy {
 //keep refs to subscriptions to be able to unsubscribe later
 private popupOpenSubscription: Subscription;
 private popupCloseSubscription: Subscription;
 private initializeSubscription: Subscription;
 private statusChangeSubscription: Subscription;
 private revokeChoiceSubscription: Subscription;
 private noCookieLawSubscription: Subscription;
  constructor(private ccService: NgcCookieConsentService, private translateService:TranslateService
  
    ){}
  title = 'Bale Construcciones';
  ngOnInit() {
    this.translateService.addLangs(['es', 'en']);
    this.translateService.setDefaultLang('es');
    const browserLang = this.translateService.getBrowserLang();
    this.translateService.use(browserLang.match(/es|en/) ? browserLang : 'es');

    // subscribe to cookieconsent observables to react to main events
   this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
    () => {
      // you can use this.ccService.getConfig() to do stuff...
    });

  this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
    () => {
      // you can use this.ccService.getConfig() to do stuff...
    });

  this.initializeSubscription = this.ccService.initialize$.subscribe(
    (event: NgcInitializeEvent) => {
      // you can use this.ccService.getConfig() to do stuff...
    });

  this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
    (event: NgcStatusChangeEvent) => {
      // this.headerCom.changeClass();
      console.log(event.status);
      // changeClass
      // if (event.status === 'allow') {
      //   this.ccService.close(false); // Hide revoke button after accepting cookies
      // }   
      // you can use this.ccService.getConfig() to do stuff...
    });

  this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
    () => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
    (event: NgcNoCookieLawEvent) => {
      // you can use this.ccService.getConfig() to do stuff...
    });
  }
  ngOnDestroy() {
// unsubscribe to cookieconsent observables to prevent memory leaks
this.popupOpenSubscription.unsubscribe();
this.popupCloseSubscription.unsubscribe();
this.initializeSubscription.unsubscribe();
this.statusChangeSubscription.unsubscribe();
this.revokeChoiceSubscription.unsubscribe();
this.noCookieLawSubscription.unsubscribe();
  }
}
