import { Component, OnInit, Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
@Pipe({ name: 'safe' })
export class VideoComponent implements OnInit {
  srcUrl: string ="https://www.youtube.com/embed/2YCuF3kFxsQ"
  constructor() { 


    
  }
  ngOnInit() {
    
  }

}
