import { Component, OnInit,Input} from '@angular/core';

@Component({
  selector: 'app-title-web',
  templateUrl: './title-web.component.html',
  styleUrls: ['./title-web.component.css']
})
export class TitleWebComponent implements OnInit {
  //este input esta preparado para recibir los titulos de cada componente
  @Input() title:string;
  constructor() { }

  ngOnInit() {
  }

}
