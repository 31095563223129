import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-elegirnos',
  templateUrl: './elegirnos.component.html',
  styleUrls: ['./elegirnos.component.css']
})
export class ElegirnosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
