import { CdkScrollable } from '@angular/cdk/scrolling';
import { Component,  AfterViewInit, ViewChild, HostListener } from '@angular/core';
import { MatDialog, MatSidenavContainer, MatSidenavContent, MatToolbar } from '@angular/material';
import { PresupuestoComponent } from '../presupuesto/presupuesto.component';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
@HostListener('window:scroll', [])
export class HeaderComponent implements AfterViewInit {
  claseCookie : string ='capaCookie';
  fillerContent = Array(50).fill(0).map(() =>
  `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
    labore et dolore magna aliqua.`);
  @ViewChild(MatSidenavContainer, {static: true }) sidenavContainer: MatSidenavContainer;
  @ViewChild(CdkScrollable, {static: true }) scrollable: CdkScrollable;
  @ViewChild(MatSidenavContent, { static: true }) content: MatSidenavContent;
  @ViewChild('toolBara', {static: true }) toolbar: MatToolbar;
  claseButtonClick: string = "mat-button-wrapper";
  constructor(public dialog: MatDialog) { }
  changeClass(){
    this.claseCookie='capaCookieOculta';
  }
  onWindowScroll() {
    if (document.body.scrollTop > 400 || (document.documentElement.scrollTop > 400 )) {
     
      document.getElementById('menuHead').classList.add('sticky');
      document.getElementById('menuHead').classList.remove('fixed');
    }
    else  if (document.body.scrollTop > 400 || (document.documentElement.scrollTop < 400 )) {
      document.getElementById('menuHead').classList.remove('sticky');
      document.getElementById('menuHead').classList.add('fixed');
    }
  }
  ngAfterViewInit() {
        this.scrollable.elementScrolled().subscribe(() => {
          const scrollTop = this.sidenavContainer.scrollable.getElementRef().nativeElement.scrollTop;
          if (scrollTop > 0) {
            this.toolbar._elementRef.nativeElement.classList.add('sticky');
            this.toolbar._elementRef.nativeElement.classList.remove('fixed');
          } else {
            this.toolbar._elementRef.nativeElement.classList.add('fixed');
            this.toolbar._elementRef.nativeElement.classList.remove('sticky');
          }
        });
    
   
  }
  toServices(){
    document.getElementById("services").scrollIntoView({behavior:'smooth'});
  }
  toInstalaciones(){
    document.getElementById("instalaciones").scrollIntoView({behavior:'smooth'});
  }
  toOpiniones(){
    document.getElementById("opinion").scrollIntoView({behavior:'smooth'});
  }
  toHome(){
    document.getElementById("fondo").scrollIntoView({behavior:'smooth'});
    this.claseButtonClick = "mat-button-wrapper-click"
  }
  toProject(){
    document.getElementById("project").scrollIntoView({behavior:'smooth'});
  }
  toTrabajamos(){
    document.getElementById("trabajo").scrollIntoView({behavior:'smooth'});
  }
  toContacto(){
    document.getElementById("piepagina").scrollIntoView({behavior:'smooth'});
  }
  toNosotros(){
    document.getElementById("elegirnos").scrollIntoView({behavior:'smooth'});
  }
  toVideo(){
    document.getElementById("videoBody").scrollIntoView({behavior:'smooth'});
  }
  toPresupuesto(){
    const dialogRef = this.dialog.open(PresupuestoComponent,{
      width: '50vw',
      height: '70vh',
      data: {name: '', animal: 'this.animal'}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
    
  }
}
