import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-proyects',
  templateUrl: './proyects.component.html',
  styleUrls: ['./proyects.component.scss']
})
export class ProyectsComponent implements OnInit {
   

  mySlideImages = ['/assets/img/Project/cocinab.jpg','/assets/img/Project/CocinaP1.png','/assets/img/Project/bar.jpg','/assets/img/Project/bar2.png','/assets/img/Project/baño1.png','/assets/img/Project/baño2.png','/assets/img/Project/obra1.jpg','/assets/img/Project/obra2.jpg','/assets/img/Project/obra3.jpg','/assets/img/Project/obra4.jpg','/assets/img/Project/obra5.jpg','/assets/img/Project/obra6.jpg','/assets/img/Project/obra7.jpg','/assets/img/Project/obra9.jpg','/assets/img/Project/obra10.jpg','/assets/img/Project/obra11.jpg'];
  myCarouselImages =['/assets/img/Project/cocinab.jpg','/assets/img/Project/CocinaP1.png','/assets/img/Project/bar.jpg','/assets/img/Project/bar2.png','/assets/img/Project/baño1.png','/assets/img/Project/baño2.png','/assets/img/Project/edificio.jpg',,'/assets/img/Project/obra1.jpg','/assets/img/Project/obra2.jpg','/assets/img/Project/obra3.jpg','/assets/img/Project/obra4.jpg','/assets/img/Project/obra5.jpg','/assets/img/Project/obra6.jpg','/assets/img/Project/obra7.jpg','/assets/img/Project/obra9.jpg','/assets/img/Project/obra10.jpg','/assets/img/Project/obra11.jpg'];
  images=[]
   mySlideOptions={items: 1, dots: true, nav: true};
   myCarouselOptions={items: 3, dots: true, nav: true};
  
  constructor() { }

  ngOnInit() {
  }

}
