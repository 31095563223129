import { Component, OnInit, Pipe } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-opiniones',
  templateUrl: './opiniones.component.html',
  styleUrls: ['./opiniones.component.css']
})
@Pipe({ name: 'safe' })
export class OpinionesComponent implements OnInit {

  
  constructor() { }

  ngOnInit() {
    
  }
 

}
