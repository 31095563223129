import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { PoliticaComponent } from '../politica/politica.component';

@Component({
  selector: 'app-piepagina',
  templateUrl: './piepagina.component.html',
  styleUrls: ['./piepagina.scss']
})
export class PiepaginaComponent implements OnInit {

  title="mapsBale";
  
  position={
    lat: 43.52847835434743,
    lng: -5.678470347288242
  };
  
  label = {
    color:'#FFF9F0',
    text:'BALE CONSTRUCCIONES',
    fontWeight: "bold"
  }
  icon = {
    url: "./assets/icons/marcadorMaps.png", 
    scaledSize: new google.maps.Size(50, 50), 
    labelOrigin: new google.maps.Point(100, 60),
  };
  
  options = {
    draggable: false, 
    icon: this.icon
 }

  
  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }
  openPoliticaPrivacidad(){
    const dialogRef = this.dialog.open(PoliticaComponent,{
      width: '700px',
      height: '700px',
      data: {name: '', animal: 'this.animal'}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
    
  }
}
