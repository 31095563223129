import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
@Component({
  selector: 'app-instalaciones',
  templateUrl: './instalaciones.component.html',
  styleUrls: ['./instalaciones.component.css']
})
export class InstalacionesComponent implements OnInit {
  private _album: Array<any> = [];
  
  constructor(    ) { 

    // for (let i = 1; i <= 1; i++) {
    //   const src = 'assets/img/Instalaciones/Instalaciones1.jpg';
    //   const caption = 'Image ' + i + ' caption here';
    //   const thumb = 'demo/img/image' + i + '-thumb.jpg';
    //   const album = {
    //      src: src,
    //      caption: caption,
    //      thumb: thumb
    //   };
 
    //   this._album.push(album);
    // }
  }
  toInstalaciones(){
    // this.router.navigate(['http://localhost:4200/'])
    document.getElementById("instalaciones").scrollIntoView({behavior:'smooth'});
  }
  ngOnInit() {
   
  }

}
